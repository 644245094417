@import url('@src/total.less');
.container {
  position: relative;
  padding-bottom: 12px;
}
.header {
  font-size: @font-size-base;
  font-weight: bold;
  line-height: 36px;

  padding-left: 40px;

  color: @grey-8;
}
.block {
  font-size: @font-size-base;

  padding-right: 12px;
  padding-left: 40px;
  + .block {
    margin-top: 8px;
  }
}
.block-header {
  line-height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.block-label {
  margin-right: 12px;
  display: inline-block;
  width: 68px;
  color: @grey-8;
}
.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 24px;
  padding: 4px 0;
}
.content-label {
  margin-right: 12px;
  display: inline-block;
  min-width: 68px;
  color: @grey-8;
  flex-shrink: 0;
}
.content-value {
  flex: 1;
}
.line {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 32px;
}
.line-label {
  margin-right: 12px;
  display: inline-block;
  min-width: 68px;
  color: @grey-8;
  flex-shrink: 0;
}
.line-value {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.files {
  line-height: 22px;

  padding: 5px 0;
}
.file {
  + .file {
    &::before {
      margin: 0 4px;

      content: '、';
    }
  }
  > a {
    color: @link-color;
  }
}
.block-date {
  color: #999;
}
.order {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 32px;
  > span {
    font-size: @font-size-sm;
    line-height: 14px;

    display: inline-block;

    box-sizing: border-box;
    min-width: 14px;
    height: 14px;
    padding: 0 2px;

    text-align: center;

    color: #fff;
    border-radius: 50%;
    background-color: @grey-8;
  }
}
.line-status {
  margin-right: 24px;
}
@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;