@import url('@src/total.less');
.container {
  margin-bottom: 24px;
  padding: 0 24px;

  box-shadow: 0 1px 3px rgb(183 187 200);
}
.header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  height: 48px;

  border-bottom: 1px solid @border-color-split;
}
.header-title {
  font-size: @font-size-base;
  font-weight: 700;

  color: @heading-color;
}
.header-date {
  font-size: @font-size-base;

  color: @heading-color;
}
.table {
  width: 100%;

  table-layout: fixed;
}
.table-th {
  line-height: 48px;

  overflow: hidden;

  height: 48px;
  padding: 0 8px;

  white-space: nowrap;
  text-overflow: ellipsis;

  color: @heading-color;
  &:nth-child(5),
  &:nth-child(6) {
    text-align: right;
  }
}
.table-td {
  line-height: 44px;

  overflow: hidden;

  height: 44px;
  padding: 0 8px;

  white-space: nowrap;
  text-overflow: ellipsis;

  color: @text-color;
  &:nth-child(5),
  &:nth-child(6) {
    text-align: right;
  }
}
.total-line {
  position: relative;

  height: 49px;

  text-align: center;

  border-bottom: 1px solid @border-color-split;
}
.total-btn {
  font-size: @font-size-base;

  height: 48px;

  cursor: pointer;

  color: @text-color-secondary;
  border: 0;
  background-color: #fff;
  > span {
    margin-right: 4px;
  }
}
.total-info {
  font-size: @font-size-base;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  color: @text-color-secondary;
  > span:nth-child(2) {
    margin: 0 8px;
  }
}

.total-fee {
  font-size: @font-size-base;
  font-weight: bold;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  color: @heading-color;
  > span:nth-child(1) {
    margin-right: 8px;
  }
}
.operate-line {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  height: 56px;
}
.operate-type {
  font-size: @font-size-base;

  color: @text-color;
}
.operate-to-pay {
  color: @error-color;
}
.operate-examine {
  color: @primary-color;
}
.operate-btn {
  width: 88px;
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;