@import url('@src/total.less');
.container {
  position: relative;
  padding-top: 80px;
}
.inner {
  position: relative;
  height: calc(100vh - 80px);
  padding-left: 160px;
}
.header {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 80px;
  padding: 0 48px;

  color: @primary-10;
  border-bottom: 1px solid #efefef;
  img {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
  span {
    font-size: 18px;

    color: @heading-color;
  }
  .login-content__header__point {
    display: inline-block;

    width: 4px;
    height: 4px;
    margin: 0 8px;

    border-radius: 50%;
    background-color: @primary-10;
  }
}
.header-logo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.back {
  color: @link-color;
  font-size: 16px;
}
.left-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 100%;
  border-right: 1px solid #efefef;
}
.left-list {
  li {
    position: relative;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    text-align: right;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
    user-select: none;
    &:hover {
      background-color: @primary-1;
    }
    &.active {
      color: @primary-color;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -1px;
        height: 40px;
        border-right: 4px solid @primary-color;
      }
    }
  }
}
.right-container {
  padding: 12px 24px;
}
@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;