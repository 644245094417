@import url('@src/total.less');

.item {
  position: relative;

  border: 1px solid @border-color-split;
  border-radius: @border-radius-base;
  box-shadow: rgb(183, 187, 200) 0 1px 3px 0;
  & + .item {
    margin-top: 24px;
  }
}

.item-no {
  font-size: @font-size-sm;
  line-height: 16px;

  position: absolute;
  top: 0;
  left: 0;

  min-width: 16px;
  height: 16px;
  padding: 0 4px;

  text-align: center;

  color: #fff;
  border-top-left-radius: @border-radius-base;
  background-color: #000;
}

.item-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  height: 48px;

  cursor: pointer;
  user-select: none;

  border-bottom: 1px solid @border-color-split;
}

.item-header__left {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  padding-left: 24px;
}

.item-header__right {
  font-size: @font-size-sm;

  padding-right: 16px;

  color: @heading-color;
}

.item-caseno {
  font-size: @font-size-base;

  display: inline-block;

  color: @heading-color;
  + .item-caseno {
    margin-left: 16px;
  }
}

.item-status {
  font-size: @font-size-sm;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  margin-left: 16px;
  padding: 2px 8px 2px 4px;

  color: #fff;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  &.pass {
    background-color: @link-color;
  }
  &.wait {
    background-color: @success-color;
  }
  &.close {
    background-color: #000;
  }
  &.unsubmit {
    background-color: @warning-color;
  }
  &.unpass {
    background-color: @error-color;
  }
}

.item-status-icon {
  font-size: @font-size-base;

  margin-right: 4px;
}

.item-body {
  min-height: 140px;
}

.item-body__left {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;

  padding-bottom: 20px;
}

.item-persno-list {
  display: inline-block;

  width: 240px;
  margin-left: 24px;
}

.item-person__title {
  font-size: @font-size-base;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  height: 32px;
  margin-top: 8px;

  color: @heading-color;
}

.item-person-icon {
  width: 12px;
  height: 14px;
  margin-right: 16px;
}

.item-person-names {
  font-size: @font-size-base;
  line-height: 28px;

  display: flex;
  flex-direction: column;

  color: @text-color-secondary;
  > span {
    display: inline-block;
    overflow: hidden;

    width: 100%;

    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.item-body__right {
  position: absolute;
  right: 0;
  bottom: 20px;

  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;

  padding-right: 24px;

  color: @grey-5;
}

.item-other {
  padding: 8px 0;

  border-bottom: 1px solid @border-color-split;
}

.item-other-line {
  line-height: 20px;

  padding: 4px 24px;
}

.item-other-line-cell {
  display: inline-block;

  width: 33.3%;
}
.item-other-line-cell-lg {
  display: inline-block;

  width: 66.6%;
}
.notice {
  font-size: @font-size-base;

  margin-left: 24px;

  color: @text-color-secondary;
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;