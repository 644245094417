@import url('@src/total.less');
.container {
  display: inline-block;
}
.block {
  position: relative;

  display: inline-block;
  overflow: hidden;
  overflow: hidden;

  width: 120px !important;
  height: 82px;
  padding: 0 !important;

  cursor: pointer;
  &.front {
    background-image: url('~@imgs/front.jpeg');
    background-size: 100% 100%;
  }
  &.back {
    background-image: url('~@imgs/back.png');
    background-size: 100% 100%;
  }
  &.disabled {
    cursor: no-drop;
  }
}
.plus {
  font-size: @font-size-base;

  position: absolute;
  top: 50%;
  left: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;
  margin-top: -16px;
  margin-left: -16px;

  content: '+';

  color: #fff;
  border-radius: 50%;
  background-color: @primary-color;;
}
.file-input {
  position: absolute;
  top: -100px;
  left: 0;
  z-index: 1;
  width: 1px;
  height: 1px;
}
.pic {
  position: relative;
  z-index: 1;

  width: 100%;
  height: 100%;

  border: 1px dashed @border-color-base;
  border-radius: @border-radius-base;
  background-color: #fff;
}
.title {
  line-height: 24px;

  text-align: center;

  color: @text-color;
}
.delBtn {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;

  width: 20px;
  height: 20px;

  border-radius: 50%;
  background-color: #fff;
  background-image: url('~@imgs/false.png');
  background-size: 100%;
  cursor: pointer;
}
@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;